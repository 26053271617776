import { GoogleMap, MarkerF, StreetViewPanorama } from '@react-google-maps/api'
import PropTypes from 'prop-types'
import { useState } from 'react'

import { useMapFilterActions } from '../../hooks/actions/useMapFilterActions'
import { useMapInitializer } from '../../hooks/useMapInitializer'
import { renderMarkerClusters } from '../../utils/geos/renderMarkerClusters'
import { AdSpaceDetails } from '../adSpaces/AdSpaceDetails'
import { MultiAdSpaceDetails } from '../adSpaces/MultiAdSpaceDetails'
import { MapLocationSearch } from './MapLocationSearch'
import { LoadingBox } from '../shared/LoadingBox'

import '../../styles/Map.css'
import { mapSettings } from '../../utils/enums/mapSettings'

const maxZoom = 17
const minZoom = 6
/**
 * This Component renders a Google Map with markers with a search bar
 * markers have a click event that opens a modal with AdSpaceDetails
 * @param {Array} markers - Array of objects with lat, lng, icon, uuid properties
 * @param {String} variant - Variant of the map (optional)
 */
export function Map({
  markers = [],
  variant,
  initialCenter = { lat: -33.44243, lng: -70.64378 },
  initialZoom = mapSettings.defaultZoom,
  showAllMarkers = false,
  streetViewOpened = false,
  activeMarkerUuid = { uuid: null, active: false },
  isLoadingMarkers = false,
  onBoundsChanged,
  ...props
}) {
  const [selectedMarkerUuids, setSelectedMarkerUuids] = useState(null)
  const [selectedMarkerLatLng, setSelectedMarkerLatLng] = useState(null)
  const [center, setCenter] = useState(initialCenter)
  const [isSearching, setIsSearching] = useState(false)
  const [zoom, setZoom] = useState(initialZoom)

  const { setZoomFilterState } = useMapFilterActions()

  const handleSetZoom = (zoom) => {
    setZoom(zoom)
    setZoomFilterState(zoom)
  }

  const handleZoom = (currentZoom, maxZoom) => {
    const newZoom = Math.min(currentZoom + 1, maxZoom)
    handleSetZoom(newZoom)
    return newZoom
  }

  const handleMarkerClick = (marker) => {
    const { num_points, item_ids, lat, lng } = marker

    if (zoom < 14 && num_points > 1) {
      setCenter({ lat, lng })
      handleZoom(zoom, maxZoom)
      return
    }

    setSelectedMarkerUuids(item_ids)
    setSelectedMarkerLatLng({ lat, lng })
    setIsSearching(false)
  }

  const { handleOnLoad, handleOnTilesLoad, mapRef } = useMapInitializer(
    showAllMarkers,
    markers,
    onBoundsChanged,
    handleSetZoom
  )

  const handleCenterChange = (newCenter) => {
    setCenter(newCenter)
    if (newCenter.lat !== initialCenter.lat || newCenter.lng !== initialCenter.lng) {
      setIsSearching(true)
    }
  }

  /* METHODS */
  const handleCloseModal = () => {
    setSelectedMarkerUuids(null)
    setSelectedMarkerLatLng(null)
  }

  return (
    <div {...props} id='map-container'>
      {
        isLoadingMarkers ?
          <div id='map-loader'>
            <LoadingBox />
          </div>
          : null
      }
      <GoogleMap
        zoom={zoom}
        onLoad={handleOnLoad}
        onTilesLoaded={handleOnTilesLoad}
        mapContainerStyle={{
          height: '100%',
          width: '100%',
          borderRadius: '15px',
          border: '1px solid #ccc'
        }}
        center={center}
        options={{
          maxZoom,
          minZoom: showAllMarkers ? minZoom : 10,
          mapTypeControl: false,
          fullscreenControl: false
        }}>
        {mapRef.current ? <StreetViewPanorama position={center} visible={streetViewOpened} /> : null}
        <MapLocationSearch setCenter={handleCenterChange} />
        {isSearching && !isLoadingMarkers && (
          <MarkerF
            key={center.lat + center.lng}
            position={center}
            animation={window.google.maps.Animation.DROP}
            options={{
              maxZoom: 15,
              minZoom: 10
            }}
          />
        )}
        {renderMarkerClusters(markers, activeMarkerUuid, handleMarkerClick)}
      </GoogleMap>

      {selectedMarkerUuids ? (
        selectedMarkerUuids.length > 1 ? (
          <MultiAdSpaceDetails
            isOpened={!!selectedMarkerUuids}
            closeModal={handleCloseModal}
            ids={selectedMarkerUuids}
            position={selectedMarkerLatLng}
            variant={variant}
          />
        ) : (
          <AdSpaceDetails
            isOpened={!!selectedMarkerUuids}
            closeModal={handleCloseModal}
            id={selectedMarkerUuids[0]}
            variant={variant}
          />
        )
      ) : null}
    </div>
  )
}

Map.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      id: PropTypes.number,
      item_ids: PropTypes.arrayOf(PropTypes.string),
      lat: PropTypes.number,
      lng: PropTypes.number,
      num_points: PropTypes.number
    })
  ),
  variant: PropTypes.string,
  initialCenter: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }),
  initialZoom: PropTypes.number,
  showAllMarkers: PropTypes.bool,
  streetViewOpened: PropTypes.bool,
  activeMarkerUuid: PropTypes.shape({
    uuid: PropTypes.string,
    active: PropTypes.bool
  })
}
