import { FormControl, InputLabel, MenuItem, Select, ListSubheader, Button } from '@mui/material'
import { Field } from 'formik'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

// CONSTANTS
const ITEM_HEIGHT = 48
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10,
      minWidth: '200px'
    }
  }
}

export const ProviderInput = ({ providers }) => {
  // HOOKS
  const { t } = useTranslation(['translation', 'adSpace'])

  // METHODS

  // RETURN
  return (
    providers
      ? <FormControl className="filter-input" variant='outlined' size='small' fullWidth>
          <InputLabel htmlFor='provider-select' >{t('provider', { ns: 'adSpace' })}</InputLabel>
          <Field
            as={Select}
            name='provider_ids'
            id='provider-select'
            label={ t('provider', { ns: 'adSpace' })}
            multiple
            MenuProps={MenuProps}
            className="filter-input provider-input"
          >
            {providers.map((provider, index) => {
              const { uuid, name } = provider
              return (
                <MenuItem
                  key={index}
                  value={uuid}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: 'secondary.main',
                      color: 'secondary.contrastText'
                    }
                  }}
                >
                  {name}
              </MenuItem>
              )
            })}
          </Field>
        </FormControl>
      : null
  )
}

ProviderInput.propTypes = {
  providers: propTypes.array.isRequired
}
