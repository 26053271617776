// TODO: rename formats to adSpaceFormats or adFormats something like that
export const formats = [
  [
    {
      name: 'Letrero publicitario',
      uuid: '67281296-bfcd-4cc8-9e15-a53e91522c88'
    },
    {
      name: 'Tótem',
      uuid: '95306580-7344-488a-b70a-83e6f9d9b4cd'
    },
    {
      name: 'Monumental',
      uuid: 'ae5965d8-9412-490f-9b6f-29827fdc8f64'
    },
    {
      name: 'Unipole',
      uuid: '8a30784e-39ab-4741-8f01-78dfa386db62'
    },
    {
      name: 'Supersite',
      uuid: '09e796db-b4e5-4d95-8509-9470bd4d7e7e'
    },
    {
      name: 'Valla',
      uuid: '068b1443-1416-466d-9f7d-9bc3ca2b9160'
    },
    {
      name: 'Caminero',
      uuid: 'e8926638-9186-44a0-99b7-c16ee3a2f6f5'
    },
    {
      name: 'Prisma',
      uuid: '68bf3a17-a90d-4eb6-be29-2dee66c00852'
    },
    {
      name: 'Pasarela',
      uuid: 'f8523e2b-49f1-4ecb-872b-13a98655eab6'
    },
    {
      name: 'Minipole',
      uuid: 'b5daeb57-efe3-4346-9c31-bf6380301997'
    },
    {
      name: 'Topsite',
      uuid: 'a71a859f-a3ba-443b-9b18-20ae4f89a338'
    },
    {
      name: 'Building',
      uuid: '2f2ecec2-265e-41d9-a0fa-74eefd0d86ae'
    },
    {
      name: 'Unipole lite',
      uuid: '7668b99b-193b-4483-b65b-a972b98e2068'
    },
    {
      name: 'Terracero',
      uuid: '6097b50c-1b71-462e-912e-03fdf0f66821'
    },
    {
      name: 'Columna',
      uuid: 'e8116999-68be-46ac-869e-eec959ffa5ef'
    },
    {
      name: 'Letreros publicitarios - Otros',
      uuid: '4a2b1913-890e-4581-a69c-bee262967671'
    }
  ],
  [
    {
      name: 'Pantallas digitales',
      uuid: '93152d56-066a-4e5e-91c5-9bdff7bad610'
    },
    {
      name: 'Pantallas digitales',
      uuid: '89618847-3776-4128-b225-897a560ce7c3'
    }
  ],
  [
    {
      name: 'Mobiliario urbano',
      uuid: 'c66d3c5a-a929-4b8b-bc53-d2ffddfc2748'
    },
    {
      name: 'Paradero',
      uuid: 'd071ae10-ae4c-48c3-9550-bae1931671bc'
    },
    {
      name: 'Paleta',
      uuid: '0b5a81e7-cde2-4393-9add-f01effbe676b'
    },
    {
      name: 'Kiosko',
      uuid: '4a6bdaaf-bc34-4b1e-84a9-b919a09b9153'
    },
    {
      name: 'Señalizador',
      uuid: '01052b2f-6407-4d47-9185-58b1d498831a'
    },
    {
      name: 'Valla alto impacto',
      uuid: 'e414e929-c43e-46eb-9866-445070d62a6e'
    },
    {
      name: 'Mobiliario urbano impreso - Otros',
      uuid: '5c829a2f-43ae-4495-80ec-c3222eff152e'
    },
    {
      name: 'Mobiliario urbano digital',
      uuid: 'fb4a6adc-f517-4207-8d65-bc1a2cb40734'
    }
  ],
  [
    {
      name: 'Bus',
      uuid: 'c802b67f-0f85-487e-ab33-2bd467b749f5'
    },
    {
      name: 'Bus - Luneta',
      uuid: 'ecad0e6c-8e82-4879-b3c4-78d1338be846'
    },
    {
      name: 'Bus - Lateral',
      uuid: '319b694c-6553-4dc8-862a-ad3244c806ae'
    },
    {
      name: 'Bus - Otros',
      uuid: 'd283fabb-1538-49a3-82fe-1e946a73d424'
    }
  ],
  [
    {
      name: 'Colectivo',

      uuid: 'b4cbbdb5-f216-4868-b761-6ee51364749f'
    },
    {
      name: 'Colectivo - Top',
      uuid: '62984d8d-b6cb-4a57-9aa3-7f19b212a4ae'
    },
    {
      name: 'Colectivo - Luneta',
      uuid: '3d71944f-ce4a-4dbd-a440-25e5618ab383'
    },
    {
      name: 'Colectivo - Otros',
      uuid: 'eebd741f-31da-4a72-aeb2-0c576e746925'
    }
  ],
  [
    {
      name: 'Metro',
      uuid: '75957e9b-6bcd-4b45-b48e-c225b289311f'
    },
    {
      name: 'Metro - Letrero publicitario',
      uuid: '127d3f32-97ad-438b-aef2-dc08a4e03693'
    },
    {
      name: 'Metro - Puerta de salida',
      uuid: '38c21097-3af8-4f0f-9156-44445decd1d0'
    },
    {
      name: 'Metro - Muro',
      uuid: 'a2bfb3c9-89d5-4400-acdb-45cc37a9969b'
    },
    {
      name: 'Metro - Tren',
      uuid: '51779827-d2fb-404c-ab91-b51335fb3bae'
    },
    {
      name: 'Metro - Pantalla digital',
      uuid: '793e5687-be61-4e08-98bb-4f0e1a1a6c70'
    },
    {
      name: 'Metro - Boletería',
      uuid: 'a17f33cc-ee1f-4dca-af9f-36bbf1956d03'
    },
    {
      name: 'Metro - Torniquete',
      uuid: 'c0bf594b-a448-4d2b-b864-025fd4bdf057'
    },
    {
      name: 'Metro - Otros',
      uuid: 'af8f28d7-e630-425b-8344-d70f84493880'
    }
  ],
  [
    {
      name: 'Centro comercial',
      uuid: '9eb6afd2-378d-49f5-bc2c-2d964653056b'
    },
    {
      name: 'Centro comercial - Letrero publicitario',
      uuid: 'ea47015c-a96c-44d6-ad94-2181bcbbd82a'
    },
    {
      name: 'Centro comercial - Pantalla digital',
      uuid: 'b98df9df-9777-4d72-8780-708a065e2157'
    },
    {
      name: 'Centro comercial - Caja de luz',
      uuid: '4dc08bc1-b5ed-4d47-ae6d-498ac74e301f'
    },
    {
      name: 'Centro comercial - Paleta',
      uuid: '95e2cc97-92b2-40c0-80ea-3daaedc33994'
    },
    {
      name: 'Centro comercial - Escalera',
      uuid: 'e87ab4e0-12df-4bec-a342-d4748b4f5c37'
    },
    {
      name: 'Centro comercial - Otros',
      uuid: '30e02222-76ce-4f72-a577-9a54ace17515'
    }
  ],
  [
    {
      name: 'Aeropuerto',
      uuid: '60bf29b4-dc43-4078-9215-2a1a604ffab2'
    },
    {
      name: 'Aeropuerto - Soporte impreso',
      uuid: 'c891b85d-aa55-4fba-86a6-723d91aa146e'
    },
    {
      name: 'Aeropuerto - Pantalla digital',
      uuid: '8c931985-d5c1-4df7-977b-b935c8feb905'
    },
    {
      name: 'Aeropuerto - Otros',
      uuid: '5a659afd-7e69-4bb4-9724-69e986d717ef'
    }
  ],
  [
    {
      name: 'Supermercado',

      uuid: 'a683579b-7ad4-4154-9670-8f51b344ee12'
    },
    {
      name: 'Supermercado - Soporte impreso',
      uuid: '9c5cab53-8a74-4ca4-b0c8-48485f1251ad'
    },
    {
      name: 'Supermercado - Pantalla digital',
      uuid: 'd170213d-ad39-4689-88ee-f7da2cf0eb77'
    },
    {
      name: 'Supermercado - Otros',
      uuid: '76fe681e-ac95-4193-aa73-e6338698c19c'
    }
  ],
  [
    {
      name: 'Terminal de buses',
      uuid: 'da1e0bd9-2ed1-4653-b334-9e76345b8c1e'
    },
    {
      name: 'Terminal de buses - Soporte impreso',
      uuid: '74ae0b0f-cbb2-44e0-ba64-297cacf4d47f'
    },
    {
      name: 'Terminal de buses - Pantalla digital',
      uuid: '7c1eda8c-7a4c-4286-baa6-0008d82e47bd'
    },
    {
      name: 'Terminal de buses - Otros',
      uuid: '2e27563f-498e-4d5a-bdd2-246ac346cb8e'
    }
  ],
  [
    {
      name: 'Cine',
      uuid: '5a8c40ff-544f-42e9-8cf6-e690b437689b'
    },
    {
      name: 'Cine - Soporte impreso',
      uuid: '508ecbaf-73d2-41f3-9ff6-28be24952cbb'
    },
    {
      name: 'Cine - Pantalla digital',
      uuid: '7e643c00-8e41-498a-8cf9-feb17ef666ab'
    },
    {
      name: 'Cine - Otros',
      uuid: '4a6f8085-64d2-4b47-9c32-f7aa09d66ec0'
    }
  ],
  [
    {
      name: 'Indoor',
      uuid: 'fde6e06f-db8b-4cc9-bcc8-8fcfc4dd6470'
    },
    {
      name: 'Indoor - Soporte impreso',
      uuid: 'cda4eed4-d397-4174-8212-9c1604f488fa'
    },
    {
      name: 'Indoor - Pantalla digital',
      uuid: '1fb9cd5b-ca58-4a83-8bdc-ce8da8fb0786'
    },
    {
      name: 'Indoor - Otros',
      uuid: '012e8ecc-6d51-4943-b4cf-5c8c8dae83f2'
    }
  ],
  [
    {
      name: 'No tradicional',
      uuid: '0f49eb35-5a3c-48ef-9318-d0c2dc232df4'
    },
    {
      name: 'Walker',
      uuid: 'a70397e5-2c10-457f-85fa-94f82db602dc'
    },
    {
      name: 'Camión LED / Impreso',
      uuid: '9565a4a3-1130-4ba8-9c83-d47f62e01a56'
    },
    {
      name: 'Globo aerostático',
      uuid: '4b87269b-896e-4a5e-8785-ecd80d381df0'
    },
    {
      name: 'Bicicleta / Scooter',
      uuid: '3405039e-88ac-44db-884c-59f665b59278'
    },
    {
      name: 'Otros',
      uuid: 'ee034790-fa2c-4ad6-bf6a-decd76fedb0d'
    },
    {
      name: 'Publicidad aérea',
      uuid: '010af4e9-7863-4e99-b752-849cc42d8a7f'
    }
  ]
]

const mapFormat = (acc, f) => {
  if (Array.isArray(f)) {
    return f.reduce(mapFormat, acc)
  }

  acc[f.uuid] = f.name
  return acc
}

export const mappedFormats = formats.reduce(mapFormat, {})
