import { FormControl, InputLabel, MenuItem, Select, ListSubheader, Button } from '@mui/material'
import { Field } from 'formik'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

// CONSTANTS
const ITEM_HEIGHT = 48
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10,
      minWidth: '200px'
    }
  }
}

export const FormatInput = ({ formats, values, setFieldValue }) => {
  // HOOKS
  const { t } = useTranslation(['translation', 'adSpace'])

  // METHODS
  const handleOnClick = (format) => {
    const newValues = format.map((option) => option.uuid)
    const oldValues = values.format_ids
    if (newValues.every((value) => oldValues.includes(value))) {
      setFieldValue(
        'format_ids',
        oldValues.filter((value) => !newValues.includes(value))
      )
    } else {
      setFieldValue('format_ids', [...oldValues, ...newValues])
    }
  }

  // RETURN
  return (
    <FormControl className='filter-input' variant='outlined' size='small' fullWidth>
      <InputLabel className='filter-input-label' htmlFor='grouped-select'>
        {t('format', { ns: 'adSpace' })}
      </InputLabel>
      <Field
        as={Select}
        name='format_ids'
        multiple
        id='grouped-select'
        label={t('format', { ns: 'adSpace' })}
        MenuProps={MenuProps}
        className='format-input filter-input'>
        {formats.map((format, index) =>
          format.map((option, optionIndex) => {
            const { uuid, name } = option
            const isHeaderGroup = optionIndex === 0
            return isHeaderGroup ? (
              <ListSubheader component='div' disableGutters className='subheader'>
                <Button fullWidth onClick={() => handleOnClick(format)}>
                  {name}
                </Button>
              </ListSubheader>
            ) : (
              <MenuItem key={`${index}-${optionIndex}`} value={uuid} className='filters-menu-item'>
                {name}
              </MenuItem>
            )
          })
        )}
      </Field>
    </FormControl>
  )
}

FormatInput.propTypes = {
  formats: propTypes.array.isRequired,
  values: propTypes.object.isRequired,
  setFieldValue: propTypes.func.isRequired
}
